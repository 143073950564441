
import { defineComponent, Ref, ref } from 'vue';
import { useStore } from 'vuex';

import { useGtag } from 'vue-gtag-next';

import Button from '@/components/Button/Button.vue';
import ContentIntro from '@/components/ContentIntro/ContentIntro.vue';
import Hero from '@/components/Hero/Hero.vue';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';
import Modal from '@/components/Modal/Modal.vue';
import Showcase from '@/components/Showcase/Showcase.vue';

import { VideoTeaser } from '../types';

import heroImage from '@/assets/mtn/header-desktop.jpg';

const SURVEY_URL =
  'https://philip-morris-germany.leadfamly.com/mtn-tippe-den-gewinner';

export default defineComponent({
  components: {
    Button,
    ContentIntro,
    Hero,
    Icon,
    Modal,
    Showcase,
  },
  setup() {
    const store = useStore();
    const { event } = useGtag();

    const userId = store.getters['authModule/userId'];
    const videos: VideoTeaser[] = store.state.mtnModule.videos;

    const isModalOpen: Ref<boolean> = ref(false);
    const selectedVideo: Ref<VideoTeaser | null> = ref(null);

    const {
      headers: { 'x-login-token': token },
    } = store.getters['authModule/authHeaders'];

    const displayVideoModal = (videoTeaser: VideoTeaser) => {
      selectedVideo.value = videoTeaser;
      isModalOpen.value = true;
      event('Mtn video button clicked', {
        event_category: `video-${videoTeaser.title}`,
        event_label: 'start-watching',
      });
    };
    const handleModalClose = () => {
      selectedVideo.value = null;
      isModalOpen.value = false;
    };

    return {
      ICON_THEMES,
      displayVideoModal,
      handleModalClose,
      heroImage,
      selectedVideo,
      surveyUrl: SURVEY_URL,
      token,
      userId,
      videos,
    };
  },
});
